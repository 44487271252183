import type { Errors } from '@corp/types/serializers'
import { notifyError } from '@shared/errors'
import { isPresent } from '@shared/object'
import { isEmail } from '@shared/regex'
import { titleize } from '@shared/string'

export const requiredErrorMessage = 'This field cannot be empty'
export const invalidErrorMessage = 'The specified value is not valid'
export const unexpectedErrorMessage = 'An unexpected error occurred, please try again or contact support'
export const unexpectedErrorReloadMessage = 'An unexpected error occurred, please try reloading the page'

export interface InputValidator<InputValue, ValidationOptions> {
  isValueValid: (value: InputValue, validationOptions: ValidationOptions) => boolean
  errorMessage: (value: InputValue, validationOptions: ValidationOptions) => string
}

export interface InputValidation<InputValue = any> {
  isValueValid: (value: InputValue) => boolean
  errorMessage: (value: InputValue, validity?: ValidityState) => string

  // Whether to display the error immediately.
  immediate?: true
}

// Validation: Checks that the input value is not empty.
//
// Example:
//   <Input field="example" required/>
export const requiredValidation: InputValidation<any> = {
  isValueValid: value => isPresent(value),
  errorMessage: (value, validity) => validity?.badInput ? invalidErrorMessage : requiredErrorMessage,
}

// Validation: Checks that the input value is an email.
//
// NOTE: Used by default when specifying `type="email"` in Input.
export const emailValidation: InputValidation<string> = {
  isValueValid: value => !value || isEmail(value),
  errorMessage: () => 'Invalid email',
}

// Validation: Checks that the input value is equal or lower than given.
//
// Example:
//   <Input field="example" type="number" :max="4"/>
export const maxValidator: InputValidator<any, { max: number }> = {
  isValueValid: (value, options) => !value || Number(value) <= options.max,
  errorMessage: (value, options) => `This field can't be higher than ${options.max}`,
}

// Validation: Checks that the input value is equal or higher than given.
//
// Example:
//   <Input field="example" type="number" :min="4"/>
export const minValidator: InputValidator<any, { min: number }> = {
  isValueValid: (value, options) => !value || Number(value) >= options.min,
  errorMessage: (value, options) => `This field can't be lower than ${options.min}`,
}

// Validation: Checks that the input value has the given length.
//
// Example:
//   <Input field="example" length="4"/>
export const lengthValidator: InputValidator<any, { length: number }> = {
  isValueValid: (value, options) => !value || String(value).length === options.length,
  errorMessage: (value, options) => `This field must have ${options.length} characters`,
}

// Validation: Checks that the input value has at most the given length.
//
// Example:
//   <Input field="example" maxlength="4"/>
export const maxlengthValidator: InputValidator<any, { length: number }> = {
  isValueValid: (value, options) => !value || String(value).length <= options.length,
  errorMessage: (value, options) => `This field can't have more than ${options.length} characters`,
}

// Validation: Checks that the input value has at least the given length.
//
// Example:
//   <Input field="example" minlength="4"/>
export const minlengthValidator: InputValidator<any, { length: number }> = {
  isValueValid: (value, options) => !value || String(value).length >= options.length,
  errorMessage: (value, options) => `This field can't have less than ${options.length} characters`,
}

// Public: Returns a validator where the options have been bound.
export function bindValidation<V, O> (validator: InputValidator<V, O>, options: O): InputValidation<V> {
  return {
    isValueValid: value => validator.isValueValid(value, options),
    errorMessage: value => validator.errorMessage(value, options),
  }
}

// NOTE: We want to avoid displaying JS errors to the user.
function isUnexpectedError (error: any) {
  return ['Reference', 'Type', 'Syntax'].some(n => error.constructor?.name?.startsWith(n))
    || error.message?.includes('code 500')
}

// Public: Unifies how we display errors to the user.
export function translateError (error: any) {
  // Unwrap error messages from request error.
  if (error?.response?.data?.errors)
    error = error.response.data.errors

  if (isUnexpectedError(error)) {
    notifyError(error)
    return [unexpectedErrorMessage]
  }

  if (isPresent(error.messages)) {
    return Object.entries(error.messages as Errors['messages'])
      .map(([field, messages]) => translateFieldErrorMessage(field, messages[0]))
  }

  const message = translateErrorMessage(error.message)
  return message ? [message] : []
}

// Public: Unifies how we display errors to the user.
export function translateErrorToString (error: any) {
  return translateError(error).join('\n')
}

// Public: Unifies how we display validation errors from the backend.
export function translateFieldErrorMessage (field: string, message: string) {
  field = titleize(field.replace(/Id$/, ''))

  switch (message) {
    case 'can\'t be blank':
    case 'is required':
      return `${field} cannot be empty`

    case 'is invalid':
    case 'validation failed':
      return `${field} is not valid`

    default:
      // Base messages are usually self-explanatory, :base is for internal usage.
      if (field === 'Base') return message

      // In some cases, the name of the field is internal, or we prefer phrasing
      // it differently. If it starts with a capital letter, avoid prefixing.
      if (message[0] === message[0].toUpperCase()) return message

      return `${field} ${message}`
  }
}

// Public: Unifies how we display validation errors from the backend.
export function translateErrorMessage (message: string) {
  switch (message) {
    case 'can\'t be blank':
    case 'is required':
      return requiredErrorMessage

    case 'is invalid':
      return invalidErrorMessage

    case 'validation failed':
      return 'Please provide a valid email address'

    default:
      return message
  }
}
