import { capitalize, camelCase, snakeCase } from 'lodash-es'
export { capitalize, camelCase, snakeCase }

const WORDS = /\S+/g

// Public: Split on whitespace and return the resulting downcased tokens.
export function tokenize (words: string) {
  return (words && words.toLowerCase().match(WORDS)) || []
}

// Public: Separates camelCase or snake_case sentences into human readable sentences
//
// Example:
//   humanize('firstName') returns 'first name'
export function humanize (words: string) {
  return snakeCase(words).replace(/_/g, ' ')
}

// Public: Make each word in the string start with an uppercase letter, followed
// by lowercase letters.
//
// Example:
//   titleize('firstName') returns 'First Name'
export function titleize (words: string) {
  return humanize(words).replace(WORDS, capitalize)
}

export function pastTense (verb: string) {
  verb = verb.toLowerCase()
  return verb.endsWith('e') ? `${verb}d` : `${verb}ed`
}
