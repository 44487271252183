<script lang="ts" setup>import { ref as _ref } from 'vue';

import { showError } from '@consumer/services/flash'

withDefaults(defineProps<{
  name: string
  cancelLabel?: string
  confirmLabel?: string
  danger?: boolean
  onConfirm:(() => any | Promise<any>)
}>(), { cancelLabel: 'Cancel',confirmLabel: 'Confirm', })

const emit = defineEmits(['cancel', 'confirm'])
let isOpen = _ref(true)

let confirmed = _ref(false)

function cancel () {
  isOpen.value = false
  emit('cancel')
}

async function confirm (event: Event) {
  return __props.onConfirm()
    .then(() => {
      isOpen.value = false
      // Prevents additional clicks on the confirm button while the dialog is closing.
      confirmed.value = true
    })
    .catch(showError)
    .finally(() => { isOpen.value = false })
}
</script>

<template>
  <GModal :name="name" :open="isOpen" class="w-md flex flex-col g-alert-modal" @close="cancel">
    <template #header>
      <span v-if="$slots.header" class="text-navy">
        <slot name="header"/>
      </span>
    </template>

    <div v-if="$slots.default" class="flex-auto flex flex-col whitespace-pre-wrap">
      <slot/>
    </div>

    <template #actions>
      <div class="mx-6 mb-6 flex gap-4">
        <GCancelButton
          :name="`close-alert-modal-button-${name}`"
          :label="cancelLabel"
          @click="cancel"
        />
        <GPromiseButton
          primary
          :danger="danger"
          :disabled="confirmed"
          :name="`close-alert-modal-button-${name}`"
          :label="confirmLabel"
          @click="confirm"
        />
      </div>
    </template>
  </GModal>
</template>

<style lang="scss">
.g-alert-modal .g-dialog-content-wrapper {
  @apply mb-0;
}
</style>
