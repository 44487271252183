import type { IconSize } from '@consumer/components/V2/V2Icon.vue'
import type { ButtonSize } from '@consumer/components/GButton.vue'

const iconSizes: Record<string, IconSize> = {
  xsmall: 'xs',
  small: 'sm',
  medium: 'md',
  large: 'lg',
  default: 'md',
} as const

export function iconSizeForButton (size: ButtonSize): IconSize {
  return iconSizes[size as any] || size as any
}
