import type { ActiveVisit } from '@inertiajs/core'
import { useInertiaListener } from '@shared/composables/inertia'
import { clamp } from '@corp/helpers/number'

function setupProgress () {
  // Amount of time to wait before displaying progress bar.
  //
  // It makes the UX smoother for fast navigations (no flash of progress).
  const delayInMillis = 250

  // The default progress to display once a navigation starts.
  const minimumProgress = 0.08

  // How much the bar should move even if no actual progress has been received.
  const trickleRate = 0.02

  // How often the bar should move even if no actual progress has been received.
  const trickleSpeed = 800

  // How long it takes to animate the progress bar.
  const transitionDuration = 300

  // The timeout id for when it should start displaying progress.
  let navigationTimeout = $ref<any>()
  let trickleInterval = $ref<any>()

  // The percentage of progress for the navigation bar.
  let progressPercentage = $ref<number>(0)

  let inProgress = $ref(false)

  function setProgress (percentage: number) {
    progressPercentage = clamp(percentage, progressPercentage || minimumProgress, 1)
  }

  function trickleProgress () {
    if (inProgress) setProgress(progressPercentage + Math.random() * trickleRate)
  }

  function startProgress () {
    inProgress = true
    setProgress(0)
    trickleInterval = setInterval(trickleProgress, trickleSpeed)
  }

  function finishProgress () {
    setProgress(1)
    setTimeout(resetProgress, transitionDuration)
  }

  function resetProgress () {
    inProgress = false
    nextTick(() => { progressPercentage = 0 })
  }

  function startNavigation () {
    clearTimers()
    navigationTimeout = setTimeout(startProgress, delayInMillis)
  }

  function finishNavigation (visit?: ActiveVisit) {
    clearTimers()

    if (!inProgress) return

    if (visit?.interrupted)
      resetProgress()
    else
      finishProgress()
  }

  function clearTimers () {
    clearTimeout(navigationTimeout)
    clearInterval(trickleInterval)
  }

  useInertiaListener('start', startNavigation)

  useInertiaListener('progress', ({ progress }) => {
    if (inProgress && progress?.percentage)
      setProgress(progress.percentage / 100 * 0.9)
  })

  useInertiaListener('finish', ({ visit }) => finishNavigation(visit))

  onUnmounted(clearTimers)

  return {
    inProgress: $$(inProgress),
    progressPercentage: $$(progressPercentage),
    startNavigation,
    finishNavigation,
    transitionDuration,
  }
}

export type ProgressApi = ReturnType<typeof setupProgress>

// eslint-disable-next-line import/no-mutable-exports
export let api: ProgressApi | undefined

export function useProgress (): ProgressApi {
  return api = setupProgress()
}
