<!--
  A GButton that will trigger an async action and display a loading state until
  that action is complete.

  NOTE: The click listener function must return a Promise, which controls the
  loading state of the button.
-->
<script setup lang="ts">import { ref as _ref } from 'vue';

import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { preserveWidthWhile } from '@shared/composables'
import { useListeners } from '@shared/vue'
import { isFunction } from '@shared/functions'
import { showError } from '@consumer/services/flash'
import { iconSizeForButton } from '@consumer/logic/icons'
import type { ButtonSize } from './GButton.vue'

withDefaults(defineProps<{
  // Text content to render inside the button, or false if it has no label.
  label?: string | false

  // The icon to render inside the button.
  icon?: IconDefinition

  // The size of the input.
  size?: ButtonSize
}>(), { size: 'large', })

defineEmits(['click'])

// Whether the action is in progress, in which case the button is disabled and
// displays a loading indicator.
let inProgress = _ref(false)

const instance = getCurrentInstance()

const onClick = useListeners(({ onClick }) => {
  if (isFunction(onClick))
    return onClick
  console.error('You must provide a @click listener in order to use PromiseButton', instance)
})

// Ignores duplicate clicks, and displays a loading indicator until complete.
function performAsyncAction (event: Event) {
  if (inProgress.value) return

  inProgress.value = true
  const promise = onClick?.(event)
  if (promise?.finally)
    promise.catch(showError).finally(() => { inProgress.value = false })
  else
    console.error('@click listeners in GPromiseButton must return a promise', { promise, instance })
}

const button = ref()
preserveWidthWhile(button, () => inProgress.value)
</script>

<template>
  <GButton
    ref="button"
    :name="label || undefined"
    :size="size"
    v-bind="$attrs"
    @click="performAsyncAction"
  >
    <LoadingIndicator v-if="inProgress" :size="iconSizeForButton(size)"/>
    <V2Icon v-else-if="icon" :icon="icon" :size="iconSizeForButton(size)"/>
    <slot v-if="label && (icon || !inProgress)">{{ label }}</slot>
  </GButton>
</template>
