<script setup lang="ts">
import type { IconSize } from '@consumer/components/V2/V2Icon.vue'

defineProps<{ size?: IconSize }>()

// Copied from `@fort-awesome/pro-duotone-svg-icons` to avoid installing it just for this.
// faSpinnerThirdDuotone
const icon = {
  prefix: 'fad',
  iconName: 'spinner-third',
  icon: [
    512,
    512,
    [],
    'f3f4',
    // eslint-disable-next-line vue/max-len
    ['M0 256C0 114.9 114.1 .5 255.1 0C237.9 .5 224 14.6 224 32c0 17.7 14.3 32 32 32C150 64 64 150 64 256s86 192 192 192c69.7 0 130.7-37.1 164.5-92.6c-3 6.6-3.3 14.8-1 22.2c1.2 3.7 3 7.2 5.4 10.3c1.2 1.5 2.6 3 4.1 4.3c.8 .7 1.6 1.3 2.4 1.9c.4 .3 .8 .6 1.3 .9s.9 .6 1.3 .8c5 2.9 10.6 4.3 16 4.3c11 0 21.8-5.7 27.7-16c-44.3 76.5-127 128-221.7 128C114.6 512 0 397.4 0 256z', 'M224 32c0-17.7 14.3-32 32-32C397.4 0 512 114.6 512 256c0 46.6-12.5 90.4-34.3 128c-8.8 15.3-28.4 20.5-43.7 11.7s-20.5-28.4-11.7-43.7c16.3-28.2 25.7-61 25.7-96c0-106-86-192-192-192c-17.7 0-32-14.3-32-32z'],
  ],
}
</script>

<template>
  <V2Icon :icon="icon as any" class="animate-spin loading-indicator"/>
</template>
