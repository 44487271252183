import GAlertModal from '@consumer/components/GAlertModal.vue'
import GConfirmModal from '@consumer/components/GConfirmModal.vue'
import { mergeProps, type AsyncComponentLoader } from 'vue'
import { addModal, removeModals, type ModalComponent, type BaseModal } from '@consumer/stores/modals'
import { camelCase, uniqueId } from '@consumer/helpers/string'
import { api } from '@corp/services/progress'
import { notifyError } from '@shared/errors'
import type { DemoStep } from '@consumer/modules/shared/HowGiftlyWorksModal.vue'
import type { CustomizerModalProps } from '@consumer/modules/customizer/CustomizerModal.vue'
import type { GiftTag, MultiplaceGift } from '@consumer/types/serializers'
import MultiPlaceGiftIdeasApi from '@consumer/api/MultiPlaceGiftIdeasApi'
import { isPromise } from '@shared/promises'
import type GiftTemplate from '@consumer/types/GiftTemplate'

interface ModalArgs {
  name?: string
  header?: any
  content: any
  props?: any
}

interface AlertModalArgs extends ModalArgs {
  danger?: boolean
  onClose?: Function
}

interface ConfirmModalArgs extends ModalArgs {
  confirmLabel?: string
  cancelLabel?: string
  onConfirm?: Function
  onCancel?: Function
  danger?: boolean
}

type ComponentPromise<T> = ReturnType<AsyncComponentLoader<T>>

// Public: Adds a new modal to the ModalManager with the specified configuration.
//
// Returns the opened modal id.
export function openModal<P> (
  component: ModalComponent<P>,
  modal: Omit<BaseModal, 'component'>,
) {
  return addModal({ component, ...modal })
}

// Public: Opens a modal component that wasn't pre-bundled.
//
// NOTE: By default the modal component path is used to create a static id,
// which prevents duplicates.
//
// NOTE: Pass `id: undefined` in the options to allow several instances of the
// same modal to be opened at the same time.
//
// Returns a promise that resolves to the opened modal id.
export function asyncOpenModal<P> (
  componentPromise: ComponentPromise<ModalComponent<P>>,
  { id, ...attrs }: BaseModal<P>['attrs'] & { id?: string } = {},
) {
  api?.startNavigation()
  return componentPromise
    .then((component) => {
      openModal((component as any).default || component, { attrs, id })
    })
    .catch((error) => {
      notifyError(error)
      openErrorAlertModal({ content: 'An unexpected error occurred, please try reloading the page' })
    })
    .finally(() => api?.finishNavigation())
}

export const openAlertModal = ({ name, header, content, danger = false, props = {}, onClose }: AlertModalArgs) => {
  // Cleanup previous alert modal if it exists
  removeModals('alert')

  addModal({
    component: GAlertModal,
    type: 'alert',
    attrs: mergeProps({
      name: name ?? uniqueId('modal-service-alert-'),
      danger,
    }, props ?? {}),
    eventListeners: {
      close: onClose,
    },
    slots: {
      header: header ? () => header : undefined,
      default: () => content,
    },
  })
}

// Public: Opens a confirmation dialog with the specified options.
//
// Returns a promise that resolves to the opened dialog id.
export function modalConfirm<Y, N> (
  attrs: ConfirmModalArgs,
) {
  if (attrs.name === undefined)
    attrs.name = `Confirm ${attrs.confirmLabel}`

  const promise: Promise<Y | N | undefined> = new Promise((resolve, reject) => {
    const onCancel = () => {
      resolve(attrs.onCancel?.())
      return promise
    }
    const onConfirm = async () => {
      try {
        resolve(await (isPromise(attrs.onConfirm) ? attrs.onConfirm : attrs.onConfirm?.()))
      }
      catch (error) {
        reject(error)
      }
      return promise
    }
    return openModal(GConfirmModal, {
      attrs: { ...attrs, onConfirm, onCancel },
      id: camelCase(attrs.header || 'confirmModal'),
      slots: {
        header: attrs.header ? () => attrs.header : undefined,
        default: () => attrs.content,
      },
    })
  })
  return promise
}

export { modalConfirm as openConfirmModal }

export function openDeleteModal ({
  confirmLabel = 'Delete',
  header = 'Are you sure?',
  ...options
}: ConfirmModalArgs) {
  return modalConfirm({ confirmLabel, danger: true, header, ...options })
}

export const openErrorAlertModal = ({
  name,
  header = 'Oops, something went wrong',
  content,
  onClose,
}: {
  name?: string
  header?: string
  content?: any
  onClose?: Function
}) => {
  openAlertModal({
    name: name ?? uniqueId('modal-service-error-alert-'),
    danger: true,
    header,
    content,
    onClose,
  })
}

interface HowGiftlyWorksModalProps {
  initialStep?: DemoStep
  gift?: GiftTemplate
}

export function openHowGiftlyWorksModal (props: HowGiftlyWorksModalProps = {}) {
  return asyncOpenModal(import('@consumer/modules/shared/HowGiftlyWorksModal.vue'), {
    id: 'how-giftly-works-modal',
    ...props,
  })
}

export function openCustomizerModal (props: CustomizerModalProps) {
  return asyncOpenModal(import('@consumer/modules/customizer/CustomizerModal.vue'), {
    id: 'customizer-modal',
    ...props,
  })
}

export async function saveMultiplace (props: CustomizerModalProps, updatedMultiplaceGift: MultiplaceGift) {
  const placeIds = updatedMultiplaceGift.places!.map(place => place.giftlyId)
  const { newPlaces } = await MultiPlaceGiftIdeasApi.updatePlaces({
    params: updatedMultiplaceGift,
    data: { placeIds },
  })

  props.gift.places = newPlaces
  if (props.multiplaceGift) {
    props.multiplaceGift.places = newPlaces
  }

  if (updatedMultiplaceGift.giftTags) {
    const giftTagIds: number[] = updatedMultiplaceGift.giftTags.map((giftTag: GiftTag) => giftTag.id)
    const tags = await MultiPlaceGiftIdeasApi.updateGiftTags({
      params: updatedMultiplaceGift,
      data: {
        giftTagIds,
      },
    })

    if (props.multiplaceGift) {
      props.multiplaceGift.giftTags = tags
    }
  }

  if (props.multiplaceGift && props.gift) {
    props.multiplaceGift.amounts = updatedMultiplaceGift.amounts
    props.gift.giftlyMetadata = toRaw(updatedMultiplaceGift.giftlyMetadata)
    props.multiplaceGift.giftlyMetadata = toRaw(updatedMultiplaceGift.giftlyMetadata)
    props.multiplaceGift.backgroundImageUrl = updatedMultiplaceGift.backgroundImageUrl
    props.gift.giftlyDesignName = updatedMultiplaceGift.giftlyMetadata.designName

    const { amounts, giftlyMetadata, backgroundImage } = updatedMultiplaceGift
    await MultiPlaceGiftIdeasApi.update({
      params: updatedMultiplaceGift,
      data: { amounts, giftlyMetadata, backgroundImage },
    })
  }
}

export function openMultiplaceCustomizerModal (props: CustomizerModalProps) {
  return asyncOpenModal(import('@consumer/modules/customizer/CustomizerModal.vue'), {
    id: 'customizer-modal',
    async onSaveMultiplace (updatedMultiplaceGift: MultiplaceGift) {
      saveMultiplace(props, updatedMultiplaceGift)
    },
    ...props,
  })
}
