import type { Ref, ComputedRef, UnwrapNestedRefs } from 'vue'
import { injectionKey } from '@shared/injection'

export type FormData = UnwrapNestedRefs<{
  serverErrors?: ComputedRef<string[] | undefined>
  value?: ComputedRef<any>
}>

export interface FormApi {
  isValid: Ref<boolean>
  displayErrors: Ref<boolean>
  disableSubmit: ComputedRef<boolean>
  submitting: Ref<boolean>
  size?: 'small' | 'large'
  translationOptions: { prefix?: string }

  originalModel: any
  registerInput (val: { field: string; formData: FormData }): void
  unregisterInput (field: string): void
  onInputValid (field: string): void

  submit (event?: SubmitEvent): Promise<boolean>

  // NestedForm: Ensures any previously set values are removed.
  unregisterNestedForm (nestedModel: string): void

  // FormInput: Gather errors from the validations run in the input.
  onInputErrors (field: string, errors: string[]): void

  // Updates the model value, which will cause an update on the input "value" prop
  // and clear any previous server errors.
  onInputValueChange (fieldProp: string, value: any): void
}

export const FORM_INJECTION_KEY = injectionKey<FormApi>('form')

export function useForm () {
  return inject(FORM_INJECTION_KEY, undefined)
}

export function useFormInput (field: string | undefined) {
  // The parent form, if there is one.
  const form = useForm()!

  // Reactive information from the parent form, if there is one.
  const formData = reactive<FormData>({})

  if (form && field) {
    onMounted(() => {
      form.registerInput({ field, formData })
    })

    onUnmounted(() => {
      form.unregisterInput(field)
    })
  }

  return { form, formData }
}
