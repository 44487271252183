<!--
The form reset event is intercepted by <BaseDialog> to automatically close the modal.
-->
<script setup lang="ts">import { computed as _computed } from 'vue';
const explicitSize = __MACROS_toRef(__props, "size");

import { toRef as __MACROS_toRef } from "vue";
import { useForm } from '@consumer/services/form'

withDefaults(defineProps<{
  label?: string

  type?: 'button' | 'reset'

  // The size of the input.
  size?: 'small' | 'medium' | 'large'
}>(), { label: 'Cancel',type: 'reset', })

const form = useForm()

const isSaving = _computed(() => form && form.submitting.value)

// Inputs can inherit their size from the parent form.
const size = _computed(() => __props.size || (form?.size) || 'large')
</script>

<template>
  <GButton
    data-testid="cancel-button"
    :type="type"
    :disabled="isSaving || $attrs.disabled as boolean"
    tertiary
    :size="size"
  >
    <slot>{{ label }}</slot>
  </GButton>
</template>
