<!--
The base button component for the Giftly portal.

All components in the consumer portal should be named with a G (Giftly) prefix.
-->
<script lang="ts" setup>import { computed as _computed } from 'vue';

import GLink from '@consumer/components/GLink.vue'
import { isProduction } from '@shared/environment'
import { iconSizeForButton } from '@consumer/logic/icons'
import type { IconDefinition } from '@fortawesome/pro-light-svg-icons'

export type ButtonSize = 'xsmall' | 'small' | 'medium' | 'large'

withDefaults(defineProps<{
  as?: any
  color?: 'gold' | 'transparent'
  danger?: boolean
  disabled?: boolean
  href?: string
  icon?: string | IconDefinition
  iconRight?: string | IconDefinition
  label?: string
  name?: string
  primary?: boolean
  processing?: boolean
  rel?: string
  round?: boolean
  secondary?: boolean
  size?: ButtonSize
  tertiary?: boolean
}>(), { size: 'small', })

const componentAs = _computed(() => __props.as ?? (__props.href ? GLink : 'button'))

const variant = _computed(() => {
  if (__props.color) return __props.color
  if (__props.secondary) return 'secondary'
  if (__props.tertiary) return 'tertiary'
  if (!__props.primary && !isProduction) {
    console.error('Please specify the button variant explicitly for ', __props.label || __props.name)
  }
  return 'primary'
})

const buttonRef = ref()
defineExpose({
  focus: () => buttonRef.value?.focus(),
  blur: () => buttonRef.value?.blur(),
  click: () => buttonRef.value?.click(),
})
</script>

<template>
  <component
    :is="componentAs"
    ref="buttonRef"
    :type="href ? undefined : 'button'"
    :name="name"
    :href="href"
    class="g-button"
    :class="[
      `g-button-${size}`,
      `g-button-${variant}`,
      {
        disabled,
        processing,
        'g-button-danger': danger,
        'g-button-round': round,
        'g-button-no-label': !(label || $slots.default),
      },
    ]"
    :disabled="disabled || undefined"
    :rel="rel"
  >
    <LoadingIndicator v-if="processing" :size="iconSizeForButton(size)"/>
    <slot v-else>
      <template v-if="icon">
        <GIcon v-if="typeof icon === 'string'" :name="icon"/>
        <V2Icon v-else :icon="icon" :size="iconSizeForButton(size)"/>
      </template>
      <template v-if="label">{{ label }}</template>
      <slot name="addonRight">
        <template v-if="iconRight">
          <GIcon v-if="typeof iconRight === 'string'" :name="iconRight"/>
          <V2Icon v-else :icon="iconRight" :size="iconSizeForButton(size)"/>
        </template>
      </slot>
    </slot>
  </component>
</template>

<style lang="scss">
a.g-button {
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.g-button {
  @apply flex font-semibold antialiased outline-none shadow-v2sm rounded-v2md;
  @apply justify-center items-center gap-2;
  @apply truncate;

  --height: 45px;

  background: var(--bg);
  border-color: var(--br, var(--bg));
  color: var(--color);
  fill: currentcolor;
  font-size: 18px;
  height: var(--height);
  stroke: currentcolor;
  transition: ease 0.5s;

  &:not(:disabled, .disabled) {
    .group:hover &,
    &:hover,
    &:focus,
    &:active {
      --bg: var(--bg-focus);

      color: var(--color-focus, var(--color));
    }

    // TODO: When the time is right, https://caniuse.com/?search=Relative%20Color%20Syntax
    // &:active {
    //   --bg: rgb(from var(--bg-focus) r g b / .8);
    // }
  }

  &.processing {
    @apply cursor-wait;
  }

  &:disabled,
  &.disabled {
    opacity: 0.4;
  }
}

.g-button-primary {
  --color: white;
  --bg: theme("colors.primary.base");
  --bg-focus: theme("colors.primary.dark");

  &.g-button-danger {
    --bg: theme("colors.red.base");
    --bg-focus: theme("colors.red.dark");
  }

  &:disabled,
  &.disabled {
    --bg: theme("colors.grey.primary");
    --color: theme("colors.v2-tertiary");

    opacity: 1;
  }
}

.g-button-secondary {
  @apply border border-solid;

  --color: theme("colors.primary.base");
  --bg: white;
  --br: theme("colors.grey.primary");
  --bg-focus: theme("colors.primary.pale");

  &.g-button-danger {
    --color: theme("colors.red.base");
    --bg-focus: theme("colors.red.pale");
  }
}

.g-button-tertiary {
  @apply border-1 border-solid;

  --bg: transparent;
  --br: theme("colors.grey.primary");
  --bg-focus: theme("colors.surface-grey");
  --color: theme("colors.v2-secondary");
  --color-focus: theme("colors.navy");

  &.g-button-danger {
    --bg-focus: theme("colors.red.pale");
    --color-focus: theme("colors.red.base");
  }
}

.g-button-gold {
  --bg: theme("colors.gold");
  --bg-focus: "#E89F1D";
  --color: theme("colors.navy");
}

.g-button-transparent {
  --color: theme("colors.v2-secondary");
  --color-focus: theme("colors.navy");
  --bg-focus: rgb(0 0 0 / 8%);

  border: none;
  box-shadow: none;
  transition-duration: 100ms;

  &.g-button-danger {
    --bg-focus: theme("colors.red.pale");
    --color-focus: theme("colors.red.base");
  }
}

.g-button-large {
  @apply px-12;

  --height: 52px;
}

.g-button-medium {
  @apply px-8;

  --height: 50px;
}

.g-button-small:not(.g-button-tab) {
  @apply px-4;

  font-size: 14px;
}

.g-button-tab {
  @apply rounded-v2sm;
  --tw-shadow: 0 3px 8px 0 rgb(17 30 54 / 2%);

  font-size: inherit;
  transition-duration: 100ms;

  &:not(.active) {
    --color: inherit;
  }

  &.active {
    --br: theme("colors.primary.base");
    --bg: theme("colors.primary.pale");

    outline: 1px solid theme("colors.primary.base");
    outline-offset: 0;
  }
}

.g-button.g-button-no-label {
  padding: initial;
  width: calc(var(--height) + 2px);
}

.g-button-round {
  @media screen and (max-width: 1023px) {
    --height: 44px;
  }

  --height: 64px;

  border-radius: 100%;
  width: var(--height);
}

.g-button.icon-button {
  @apply rounded-full inline-flex outline-none leading-none;
  --p: 0.5rem;
  --height: auto;

  aspect-ratio: 1 / 1;
  margin-bottom: calc(var(--p) * -1);
  margin-top: calc(var(--p) * -1);
  padding: var(--p);

  &.g-button-small {
    --p: 0.6rem;
  }

  &.g-button-medium {
    --p: 0.7rem;
  }

  &.g-button-large {
    --p: 0.8rem;
  }
}
</style>
