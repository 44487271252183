<script lang="ts" setup>import { ref as _ref } from 'vue';

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import { isMobile } from '@consumer/utils/breakpoints'
import { trackClick, trackEvent, trackModal } from '@shared/tracking'
import { whenever } from '@vueuse/core'
import { fadeInOut, modalDropdown } from '@consumer/utils/transitions'
import { useListeners } from '@shared/vue'
import { isFunction } from '@shared/functions'
import { faClose } from '@fortawesome/pro-regular-svg-icons'
import type GIconButton from './GIconButton.vue'

defineOptions({
  inheritAttrs: false,
})

withDefaults(defineProps<{
  name: string
  appear?: boolean
  persistent?: boolean // If true, modal cannot be dismissed by clicking outside or hitting ESC
  fullscreenMobile?: boolean
  fullscreen?: boolean
  hideCloseButton?: boolean
  disableClose?: boolean
}>(), { persistent: false,fullscreenMobile: false,fullscreen: false, })

let open = (defineModel<boolean>('open', { default: true }))

const hasCloseListener = useListeners(listeners => isFunction(listeners.onClose))

const emit = defineEmits<{ close: [closeModal: Function]; remove: [] }>()

const closeButtonEl = _ref<InstanceType<typeof GIconButton>>()

// Emit an event to notify that the user intends to close the modal.
//
// NOTE: When the parent component adds a @close listener, it must use the
// callback to manually close the modal.
function stopAndCloseModal () {
  if (hasCloseListener) {
    emit('close', () => { open.value = false })
  }
  else {
    open.value = false
  }
}

// 'reset' is emitted by form elements when GCancelButton is clicked.
//
// NOTE: By leveraging the native 'reset' event, there is no need to manually
// add a close listener on every modal forms.
function onFormReset () {
  stopAndCloseModal()
}

function closeModal () {
  trackEvent('click', closeButtonEl.value?.$el)
  stopAndCloseModal()
}

function onClickOutside () {
  trackEvent('click', `close-modal-outside-${__props.name}`)
  stopAndCloseModal()
}

const slots = useSlots()

const isMobileFullscreen = computed(() => isMobile.value && __props.fullscreenMobile)

whenever(() => open.value, () => trackModal(__props.name))
</script>

<template>
  <TransitionRoot as="template" :show="open" :appear="appear" @afterLeave="emit('remove')">
    <Dialog
      as="div"
      class="g-dialog fixed inset-0 w-full flex justify-center items-start z-dialog p-2 lg:p-4
      text-v2-secondary font-medium lg:pt-24"
      :class="{ '!p-0': fullscreenMobile , '!lg:p-0': fullscreen }"
      :initialFocus="closeButtonEl?.$el"
      :static="true"
      @close="!persistent && !disableClose && onClickOutside()"
    >
      <TransitionChild as="template" :appear="appear" v-bind="fadeInOut">
        <div class="fixed inset-0 bg-overlay bg-opacity-40 transition-opacity"/>
      </TransitionChild>
      <TransitionChild as="template" :appear="appear" v-bind="modalDropdown">
        <DialogPanel
          as="div"
          class="
            relative max-h-full mx-auto
            bg-white border-solid border-1 border-grey-primary
            flex flex-col justify-evenly
            g-dialog-panel
          "
          :class="[
            isMobileFullscreen
              ? 'fixed inset-0 max-w-full h-full !border-0'
              : 'max-w-440px',
            fullscreen
              ? 'fixed inset-0 lg:max-w-full h-full rounded-none'
              : 'rounded-v2md rounded-v2lg lg:max-w-screen-lg'
          ]"
          v-bind="$attrs"
          aria-hidden="false"
          aria-modal="true"
          @click="trackClick"
        >
          <GIconButton
            v-if="!hideCloseButton"
            id="close-modal-button"
            ref="closeButtonEl"
            :name="`close-modal-icon-button-${name}`"
            class="-mr-3 g-dialog-close absolute z-dialog"
            :disabled="disableClose"
            style="right: 24px; top: 24px;"
            :icon="faClose"
            size="medium"
            label="Close Modal"
            @click="closeModal"
          />

          <DialogTitle
            v-if="slots.header"
            class="font-semibold text-lg p-6 border-b-1 border-b-grey-primary pb-4 g-dialog-title"
          >
            <slot name="header"/>
          </DialogTitle>

          <div class="flex-auto flex flex-col overflow-y-auto g-dialog-content" @reset="onFormReset">
            <div class="h-full p-6 g-dialog-content-wrapper">
              <slot/>
            </div>
          </div>
          <slot name="actions"/>
        </DialogPanel>
      </TransitionChild>
      <button class="opacity-0 absolute bottom-0"/>
    </Dialog>
  </TransitionRoot>
</template>

<style lang="scss">
.g-dialog {
  .g-dialog-panel {
    box-shadow: 0 4px 20px rgb(0 0 0 / 15%);
  }

  .g-button-large:not(.g-button-no-label) {
    width: 100%;
  }
}

#close-modal-button:focus:not(:hover, :active) {
  --color-focus: var(--color);
  --bg-focus: var(--bg);
}
</style>
